import dataText from '../components/gui/gui-typo/data';

const itemsData = [
  {
    title: 'Общие положения',
  },
  {
    title: 'Определение терминов',
  },
  {
    title: 'Предмет соглашения',
  },
  {
    title: 'Права и обязанности сторон',
  },
  {
    title: 'Использование сайта',
  },
  {
    title: 'Ответственность',
  },
  {
    title: 'Нарушение условий пользовательского соглашения',
  },
  {
    title: 'Разрешение споров',
    text: 'lol',
  },
];

// eslint-disable-next-line no-param-reassign,no-return-assign
itemsData.forEach((item) => item.text = item.text ? item.text : dataText.listItem);

export default itemsData;
