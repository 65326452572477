import React from 'react';
import StandardPage from '../components/page/page-standard';
import itemsData from '../__mocks__/cookie';

const pageName = 'cookie-page';

const CookiePage = () => (
  <StandardPage pageName={pageName} title="Политика Cookie">

    <ol className="list list_num">

      {itemsData.map((item, key) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className="list__item" key={key}>
          <div className="list__item-title page__title page__title_h2">{item.title}</div>
          <div className="list__item-body page__limited-text">{item.text}</div>
        </li>
      ))}

    </ol>

  </StandardPage>
);

export default CookiePage;
